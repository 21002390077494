<template>
  <div
    class="termination"
    :class="[
      { 'animate__animated animate__zoomOutLeft': isDeleting },
      { deleting: wasDeleted },
    ]"
  >
    <div class="row box box--default">
      <div
        class="row__data row__data--medium row__data--title row__data--fixed"
      >
        Kündigung
      </div>
      <div class="row__data row__data--medium">
        <div class="termination__data">{{ category }}</div>
      </div>
      <div class="row__data row__data--medium">
        <div class="termination__data">{{ terminationDate }}</div>
      </div> 
      <div class="row__data row__data--large">
        <div class="termination__data">{{ companyName }}</div>
      </div>
      <div class="row__data row__data--large">
        <EditableComment
          :comment="comment"
          :isRequired="true"
          :updateComment="updateTerminationComment"
        />
      </div>
      <div class="row__data row__data--medium row__data--half row__data--fixed">
        <div
          class="row__data row__data--small row__data--pdf icon icon__pdf"
          :class="{ 'icon__pdf--active': files.length }"
          @click="openPolicyFilesModal()"
        ></div> 
        <div
          class="row__data row__data--small icon icon__delete"
          @click="deleteTermination()"
        ></div>
      </div>
    </div>
  </div>
</template>

<script>
import { formatDisplayDate } from "@/helpers/dateFormatters";
import EditableComment from "@/partials/EditableComment"; 
export default {
  name: "TerminationService",

  components: {
    EditableComment, 
  },

  props: {
    service: {
      required: true,
      type: Object,
    },

    terminations: {
      required: true,
      type: Array,
    },
  },

  data() {
    return {
      isDeleting: false,
      wasDeleted: false,
      showDateEditPopup: false
    };
  },

  computed: {
    category() {
      return this.service.service.branch_category.code;
    },

    terminationDate() {
      return formatDisplayDate(this.service.termination_date);
    },

    companyName() {
      return this.service.company.name;
    },

    comment() {
      return this.terminations.find((t) => t.id === this.service.termination_id)
        .comment;
    },

    files() {
      return this.terminations.find((t) => t.id === this.service.termination_id)
        .termination_files;
    },
  },

  methods: {
    async deleteTermination() {
      this.isDeleting = true;
      await this.$store.dispatch("deleteTerminationService", this.service.id);
      this.wasDeleted = true;
      setTimeout(async () => {
        await this.getGlobalCustomer();
      }, 300);
    },

    async updateTerminationComment(comment) {
      await this.$store.dispatch("updateTerminationComment", {
        terminationId: this.service.termination_id,
        comment,
      });
      await this.getGlobalCustomer();
    },

    async getGlobalCustomer() {
      const { id } = this.$route.params;
      await this.$store.dispatch("getGlobalCustomer", id);
    },

    openPolicyFilesModal() {
      this.$store.commit("setPolicyFileId", this.service.termination_id);
      this.$store.commit("setPolicyFiles", this.files);
      this.$store.commit("setPolicyFilesModalIsTermination", true);
      this.$store.commit("setShowPolicyFilesModal", true);
    },
  },
};
</script>

<style lang="scss" scoped>
.termination {
  border-radius: var(--radius-1);
  transition: all 0.3s ease;
  max-height: 66px;
  margin-bottom: 15px;
  position: relative;

  .row {
    background: var(--bg-3);
    box-shadow: none;
    cursor: default;
    margin-bottom: 0;

    &__data {
      justify-content: flex-start;
      &--title {
        color: var(--text-12);
        font-size: var(--medium);
      }
    }

    &__data--half {
      flex-direction: row !important;
    }
  }

  &__data {
    color: var(--text-9);
  }
}

[data-theme="dark"] .termination{
  background: var(--bg-14);
}
</style>
