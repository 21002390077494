<template>
  <div class="customer__form modal modal--default">
    <div class="modal__panel">
      <div class="modal__logo-wrapper">
        <div class="modal__logo"></div>
        <div class="modal__logo-title">The One System</div>
      </div>

      <div class="modal__main-icon modal__main-icon--setting"></div>
      <div class="modal__close" @click="closeModal()"></div>

      <div class="form">
        <div class="navigators">
          <div class="navigator navigator--active">
            <div class="navigator__bullet"></div>
            <div class="navigator__title">{{ $t(`addRoleModal.${"title"}`) }}</div>
          </div>
        </div>
        <div class="add__form">
          <transition name="fade" mode="out-in">
             
              
            
            <div class="add__content">
              <div class="form__field">
                <div class="user__select" v-if="$checkRolePermission('team_access')">
                  <VSelect
                    class="select select--default"
                    :options="teams"
                    :reduce="(team) => team.id" 
                    label="name"
                    placeholder="Select Team" 
                    :class="{ 'input--error': selectedTeamHasError }"  
                    v-model="selectedTeam"
                  /> 
                </div>
                <div class="form__error" v-if="selectedTeamHasError" >
                   {{ $t(`addRoleModal.${"teamErrorMsg"}`) }}  
                </div>
              </div>
              <div class="form__field role-name-field">
                <input
                  type="text"
                  class="input input--default"
                  :class="{ 'input--error': roleNameHasError }"  
                  :placeholder="$t(`addRoleModal.${'rolePlaceholder'}`)" 
                  v-model="roleName"
                />
                <div class="form__error" v-if="roleNameHasError">
                  {{ $t(`addRoleModal.${"roleErrorMsg"}`) }}
                </div>
              </div>

             
            </div>
          </transition>
        </div>
      </div>
      <div
        v-if="isRequesting"
        class="modal__button modal__button--submit button button--default button--loading"
      ></div>
      <button
        v-else
        class="modal__button modal__button--submit button button--default"
        @click="addRole()"
      >
        <span>{{ $t(`addRoleModal.${"addRoleBtn"}`) }}</span>
      </button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "AddRoleModal",

  watch: {
    roleName(value) {
      if (value) {
        this.roleNameHasError = false;
      } else {
        this.roleNameHasError = true;
      }
    },

    selectedTeam(value) {
      if (value) {
        this.selectedTeamHasError = false;
      } else {
        this.selectedTeamHasError = true;
      }
    },
  },

  data() {
    return {
      roleName: "",
      roleNameHasError: false,
      isRequesting: false,

      selectedTeam: '',
      selectedTeamHasError: false,
    };
  },

  mounted(){
     this.getTeams()
  },

  computed: {
    ...mapGetters([ "teams", "roles",  "selectedRole", 'permissions' ]),
  },

  methods: {
    closeModal() {
      this.$store.commit("setShowAddRoleModal", false);
    },

    async getTeams(){ 
      await this.$store.dispatch("getTeams");   
    },

    async addRole() {
      this.isRequesting = true;
      if (this.roleName && this.selectedTeam) {
        await this.$store.dispatch("createRole", { title: this.roleName, team_id: this.selectedTeam });
        
        //await this.$store.dispatch("getRolesByTeam", null);
        this.$store.commit("setRoles", []);
        this.$store.commit("setTeams", []);
        this.$store.commit("setPermissions", []);  
        await this.$store.dispatch("getTeams");   


        this.closeModal();
      } else {
     
        
        if(this.roleName == '' || this.roleName == null){
          this.roleNameHasError = true;
        } 
        if(this.selectedTeam == '' || this.selectedTeam == null){
          this.selectedTeamHasError = true;
        }
      }
      this.isRequesting = false;
    },
  },
};
</script>

<style scoped lang="scss">
.form__field {
  margin-top: 80px;
}

.modal__button {
  left: 50%;
  transform: translateX(-50%);
  bottom: 200px;
}
 
.role-name-field{
  margin-top: 30px;
}
</style>
