<template>
  <div class="terminations">
    <div class="terminations__header">
      <div class="terminations__title">
        KÜNDIGUNG
      </div>
      <div class="terminations__hr"></div>
    </div>
    <div class="table__header">
      <div class="table__label table__label--medium table__label--fixed">
        Status
      </div>
      <div class="table__label table__label--medium">Category</div>
      <div class="table__label table__label--medium">Kündigung Date</div>
      <div class="table__label table__label--large">Firma</div>
      <div class="table__label table__label--large">Comment</div>
      <div class="table__label table__label--medium table__label--fixed">
        Tools
      </div>
    </div>
    <TerminationService
      v-for="service in terminationServices"
      :key="`${service.termination_id}${service.id}`"
      :service="service"
      :terminations="terminations"
    />
  </div>
</template>

<script>
import TerminationService from "@/components/AccountDetails/TerminationService";
export default {
  name: "TerminationsTable",
  components: {
    TerminationService,
  },
  props: {
    terminations: {
      required: true,
      type: Array,
    },
  },

  computed: {
    terminationServices() {
      return this.terminations
        .map((termination) => termination.termination_service)
        .flat();
    },
  },
};
</script>

<style lang="scss" scoped>
.terminations {
  position: relative;
  border-bottom: 1px solid var(--border-14);
  margin-bottom: 15px;
  z-index: 0;

  &::after {
    position: absolute;
    content: "";
    width: 100%;
    height: calc(100% + 60px);
    background: var(--bg-20);
    top: -60px;
    z-index: -1;
  }

  &__header {
    margin: 0 0 15px 30px;
    display: flex;
    align-items: center;
    position: relative;
    z-index: 1;
  }

  &__title {
    text-transform: uppercase;
    margin-right: 5px;
    font-size: var(--small);
    color: var(--text-2);
  }

  &__hr {
    height: 1px;
    background: var(--border-14);
    flex: 1;
  }

  .table__header {
    background: transparent;
    box-shadow: none !important;

    .table__label {
      font-size: var(--x-smaller);
      color: var(--text-13);
    }
  }
}

[data-theme="dark"] .terminations { 
  &::after { 
    background: #313B47; 
  }
  }
</style>
