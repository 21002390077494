<template>
  <div
    class="policy-accident"
    :class="[
      `policy-accident--${statusColor}`,
      { 'animate__animated animate__zoomOutLeft': isDeleting },
      { deleting: wasDeleted },
    ]"
  >
    <div class="policy-accident__row">
      <div
        class="policy-accident__data policy-accident__data--large policy-accident__data--accident-date"
      >
        <div>
          <div>
            {{ userName }}
          </div>
          <div>{{ formatDisplayDate(accident.date) }}</div>
        </div>
        <div class="policy-accident__contact">
          <a
            class="icon icon__phone"
            :href="
              accident.user_id.phone
                ? `tel:${accident.user_id.phone}`
                : 'javascript:void(0)'
            "
            :class="{ 'icon__phone--active': accident.user_id.phone }"
          ></a>
          <a
            class="icon icon__mail"
            :href="
              accident.user_id.email
                ? `mailto:${accident.user_id.email}`
                : 'javascript:void(0)'
            "
            :class="{ 'icon__mail--active': accident.user_id.email }"
          ></a>
        </div>
      </div>
      <div class="policy-accident__data policy-accident__data--large">
        <EditableComment :comment="accident.comment" :readonly="true" />
      </div>
      <div class="policy-accident__data policy-accident__data--medium">
        {{ price }}
      </div>
      <div class="policy-accident__data policy-accident__data--medium">
        <div class="policy-accident__status-badge"></div>
        <span>{{ accident.accident_status.name }}</span>
      </div>
      <div class="policy-accident__data policy-accident__data--large">
        <div class="icon"></div>
        <div
          class="icon icon__pdf"
          :class="{ 'icon__pdf--active': accident.accident_files.length }"
          @click="
            openPolicyFilesModal(
              accident.id,
              accident.accident_files,
              'accidentChildren'
            )
          "
        ></div>
        <div
          class="icon icon__edit"
          @click="openEditAccidentModal(accident)"
        ></div>
        <div class="icon icon__delete" @click="removeAccident()"></div>
        <div class="icon"></div>
      </div>
    </div>
  </div>
</template>

<script>
import EditableComment from "@/partials/EditableComment";
import { formatDisplayDate } from "@/helpers/dateFormatters";
import statics from "@/statics";
export default {
  name: "AccidentChildren",

  components: {
    EditableComment,
  },

  props: {
    accident: {
      required: true,
      type: Object,
    },

    openEditAccidentModal: {
      required: true,
      type: Function,
    },

    openPolicyFilesModal: {
      required: true,
      type: Function,
    },
  },

  data() {
    return {
      formatDisplayDate,
      statics,
      isDeleting: false,
      wasDeleted: false,
    };
  },

  computed: {
    userName() {
      return `${this.accident.user_id.first_name} ${this.accident.user_id.last_name}`;
    },

    statusColor() {
      return this.statics.accidentStatuses.find(
        (status) => status.id === this.accident.accident_status.id
      ).name;
    },

    price() {
      return this.accident.price
        ? `${this.accident.price} .-CHF`
        : statics.empty;
    },
  },

  methods: {
    async removeAccident() {
      try {
        this.isDeleting = true;
        await this.$store.dispatch("removeAccident", this.accident.id);
        this.wasDeleted = true;
        setTimeout(async () => {
          await this.$store.dispatch(
            "getGlobalCustomer",
            this.$route.params.id
          );
        }, 300);
      } catch (err) {
        console.error(err);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.policy-accident {
  border-top: 1px solid var(--border-7) !important;

  &--processing {
    .policy-accident__status-badge {
      background: #7f8fa4;
    }
  }

  &--accepted {
    .policy-accident__status-badge {
      background: #22db8e;
    }
  }

  &--declined {
    .policy-accident__status-badge {
      background: #db2222;
    }
  }

  &__data {
    padding-left: 40px;
    color: var(--text-9);
    &--small {
      flex: 1;
    }
    &--medium {
      flex: 2;
    }
    &--large {
      flex: 3;
    }

    &--accident-date {
      justify-content: space-between;
    }
  }

  &__contact {
    display: flex;

    .icon__phone {
      margin-right: 15px;
    }
  }

  &__status-badge {
    height: 16px;
    width: 16px;
    border-radius: 50%;
    margin-right: 10px;
  }

  .comment {
    background: var(--bg-1);
  }

  .icon {
    margin-right: 15px;
  } 
}

 
</style>
