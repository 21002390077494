import api from "@/api";
import router from "@/router";

export default {
  state: {
    isLoggedIn: false,
    accessToken: "",

    loggedUser: JSON.parse(localStorage.getItem("user")) || {},
  },
  getters: {
    accessToken: (state) => state.accessToken,
    loggedUser: (state) => state.loggedUser,
    isLoggedIn: (state) => state.isLoggedIn,

  },
  mutations: {
    setAccessToken: (state, data) => {
      state.accessToken = data;
    },
    setLoggedUser: (state, data) => {
      state.loggedUser = data;
    },
    setIsLoggedIn: (state, data) => {
      state.isLoggedIn = data;
    },

  },
  actions: { 
    login: async ({ commit }, { email, password }) => {
      try {
        const response = await api({ requiresAuth: false }).post("/login", {
          email,
          password,
        });

        const { message } = response.data;

        commit("setToast", {
          display: true,
          type: "success",
          message: message,
        });

        return true;

      } catch (err) {
        const errorMessage = err.response?.data?.message || "An error occurred during login.";

        commit("setToast", {
          display: true,
          type: "error",
          message: errorMessage,
        });

        console.error("Login error:", err);

        return false;
      }
    },

    sendAuthCode: async ({ commit }, { email, code }) => {
      try {
        const response = await api({ requiresAuth: false }).post("/authenticate-code", {
          email,
          code
        });

        const { access_token, user } = response.data;
        const { roles } = user;
        const permissions = roles.map((role) => role.permissions).flat();
        commit("setAccessToken", access_token);
        localStorage.setItem("access_token", access_token);
        localStorage.setItem("needs_reset", user.needs_reset);
        commit("setLoggedUser", user);
        commit("setIsLoggedIn", true);
        commit("setRolePermissions", permissions);
 
        localStorage.setItem("user", JSON.stringify(user));
        if (user.settings && user.settings.locale) {
          window.localStorage.setItem("language", user.settings.locale); 
        }

        if (user.needs_reset)
          router.push({
            name: "AccountDetails", params: {
              entity: "profile",
              id: user.id,
            },
          });
        else
          router.push({ name: "Dashboard" }); 
      } catch (err) {
        if (err.response.data.message) {
          commit("setToast", {
            display: true,
            type: "error",
            message: err.response.data.message,
          });
        }

        console.error(err);
      }
    },

    resendAuthCode: async ({ commit }, { email }) => {
      try {
        await api({ requiresAuth: false }).post("/resend-code", {
          email,
        });
      } catch (err) {
        if (err.response.data.message) {
          commit("setToast", {
            display: true,
            type: "error",
            message: err.response.data.message,
          });
        }
        console.error(err);
      }
    },

    logout: async ({ commit }) => {
      try {
        const token = localStorage.getItem("access_token");
        await api({ requiresAuth: true }).get(`/logout?${token}`);
        window.localStorage.removeItem("access_token");
        window.localStorage.removeItem("user");
        commit("setIsLoggedIn", false);
        router.push({
          name: "Login",
        });
      } catch (err) {
        console.error(err);
      }
    },

    // getLoggedUserData: async ({ commit }, userId) => {
    //   try {
    //     const response = await api({ requiresAuth: true }).get(
    //       `/v1/users/${userId}`
    //     );
    //     const { status } = response;
    //     const { data } = response.data;
    //     if (status === 200) {
    //       commit("setLoggedUser", data);
    //       commit("setIsLoggedIn", true);
    //       localStorage.setItem("user", JSON.stringify(data));
    //       router.push({ name: "Dashboard" });
    //     }
    //   } catch (err) {
    //     console.error(err);
    //   }
    // },
    updateLoggedUser: async ({ commit }, userId) => {
      try {
        const response = await api({ requiresAuth: true }).get(
          `/v1/users/${userId}`
        );
        const { data } = response.data;
        const { roles } = data;
        const permissions = roles.map((role) => role.permissions).flat();
        commit("setRolePermissions", permissions);
        commit("setLoggedUser", data);
        localStorage.setItem("user", JSON.stringify(data));
      } catch (err) {
        console.error(err);
      }
    },
  },
};
