<template>
  <div
    class="popup"
    :style="{ left: `${x}px`, top: `${y}px` }"
    :class="`popup--${position}`"
    ref="popup"
  >
    <div class="popup__tools">
      <VSelect
        v-if="items && Array.isArray(label)"
        class="select select--default"
        :options="items"
        :reduce="(item) => item.id"
        :getOptionLabel="(item) => `${item[label[0]]} ${item[label[1]]}`"
        :placeholder="selected"
        v-model="value"
        ref="vselect"
      />
      <VSelect
        v-else-if="items"
        class="select select--default"
        :options="items"
        :reduce="(item) => item.id"
        :label="label"
        :placeholder="selected"
        v-model="value"
        ref="vselect"
      />
      <Datepicker
        v-else-if="isDate"
        class="input input--default"
        ref="datepicker"
        :config="statics.datePickerConfig"
        :value="this.selected"
        @input="value = $event"
      />
      <input
          v-else-if="isPrice"
          type="number"
          min="0"
          step=".01"
          class="input input--default"
          :value="selected"
          @input="value = $event.target.value"
          ref="priceInput"
      />
      <input
        v-else
        type="text"
        class="input input--default"
        :value="selected"
        @input="value = $event.target.value"
        ref="textInput"
      />
      <div class="popup__button popup__button--confirm" @click="submit()"></div>
      <div class="popup__button popup__button--cancel" @click="close()"></div>
    </div>
  </div>
</template>

<script>
import statics from "@/statics";
import { formatDisplayDate } from "@/helpers/dateFormatters";
export default {
  name: "PopupEditer",

  props: {
    selected: {
      required: false,
      type: [String, Number],
    },
    items: {
      required: false,
      type: Array,
    },
    position: {
      required: false,
      type: String,
    },
    label: {
      required: false,
      type: [String, Array],
    },
    isDate: {
      required: false,
      type: Boolean,
    },
    isPrice:{
      required: false,
      type: Boolean
    },
    allowNegative:{
      required: false,
      type: Boolean
    }
  },

  data() {
    return {
      value: "",
      statics,
      formatDisplayDate,
      x: 0,
      y: 0,
    };
  },

  mounted() {
    this.computePopupLocation();
    document.addEventListener("click", this.event);
    if (!this.items) {
      this.value = this.selected;
    }
    if (this.isDate) {
      this.$refs.datepicker.$el.value = this.formatDisplayDate(this.selected);
    }
    this.$nextTick(() => {
      if (this.isPrice) {
        this.$refs.priceInput.focus();
      } else if (this.isDate) {
        //TODO
        //this.$refs.datepicker.$el.focus();
      } else if (this.items && Array.isArray(this.label)) {
        //TODO
        //this.$refs.vselect.focus();
      } else {
        this.$refs.textInput.focus();
      }
    });
  },

  destroyed() {
    document.removeEventListener("click", this.event);
  },

  methods: {
    event(event) {
      const { target } = event;
      const { popup } = this.$refs;

      if (
        target === popup ||
        target === popup.parentElement ||
        [...popup.getElementsByTagName("*")].includes(target)
      ) {
        this.$emit("togglePopup", true);
      } else if (
        document.querySelector(".flatpickr-calendar") &&
        [
          ...document
            .querySelector(".flatpickr-calendar")
            .getElementsByTagName("*"),
        ].includes(target)
      ) {
        this.$emit("togglePopup", true);
      } else {
        this.close();
      }
    },



    submit() { 
      if (this.isPrice && !this.allowNegative && (isNaN(this.value) || this.value <= 0)){
        alert('Please enter a valid price greater than 0.');
      } else {
        this.$emit("submit", this.value);
        this.close();
      }
    },

    computePopupLocation() {
      const { popup } = this.$refs;
      this.x =
        popup.parentElement.getBoundingClientRect().left +
        popup.parentElement.getBoundingClientRect().width +
        10;
      this.y = popup.parentElement.getBoundingClientRect().top - 20;

      this.$nextTick(() => {
        const popupRect = popup.getBoundingClientRect();

        this.isOutside = popupRect.right > window.innerWidth;

        if (this.isOutside) {
          this.x =
            popup.parentElement.getBoundingClientRect().left -
            popupRect.width -
            10;
        }
      });
    },

    close() {
      this.$emit("togglePopup", false);
    },
  },
};
</script>

<style scoped lang="scss">
.popup {
  position: fixed;
  z-index: 3;
  width: 320px;
  border-radius: var(--radius-1);
  border: 1px solid var(--border-2);
  background: var(--bg-1);
  box-shadow: 0px 3px 6px var(--shadow-7);
  padding: 10px 20px;
  cursor: default;

  &--left {
    left: initial;
    right: calc(100% + 10px);
  }

  .select {
    min-width: 200px;
    max-width: 200px;
  }

  &__tools {
    display: flex;
    align-items: center;
  }

  &__button {
    min-width: 24px;
    height: 24px;
    cursor: pointer;
    transition: all 0.3s ease;

    &--confirm {
      background: url("../assets/icons/default/confirm_icon.svg");
      margin: 0 15px;

      &:hover {
        background: url("../assets/icons/default/confirm_hover_icon.svg");
      }
    }

    &--cancel {
      background: url("../assets/icons/default/close_icon.svg");

      &:hover {
        background: url("../assets/icons/default/close_hover_icon.svg");
      }
    }
  }
}
</style>
