<template>
  <div class="customer__form modal modal--default">
    <div class="modal__panel">
      <div class="modal__logo-wrapper">
        <div class="modal__logo"></div>
        <div class="modal__logo-title">The One System</div>
      </div>

      <div class="modal__main-icon modal__main-icon--provision"></div>
      <div class="modal__close" @click="closeModal()"></div>
      <div class="form">
        <div class="add__form">
          <transition name="fade" mode="out-in">
            <div class="add__content">
              <div class="modal__provision">
                <div class="modal__provision-title">{{ $t(`policyProvision.${"title"}`) }}</div>
                <div class="modal__provision-container">
                  <div class="modal__provision-row">
                    <div class="form__field form__field--margin">
                      <div class="modal__provision-label">{{ $t(`policyProvision.${"customer"}`) }}:</div>
                      <div class="modal__provision-data">
                        {{ parseCustomerName(customer) || statics.empty }}
                      </div>
                    </div>
                    <div class="form__field">
                      <div class="modal__provision-label">{{ $t(`policyProvision.${"company"}`) }}:</div>
                      <div class="modal__provision-data">
                        {{ serviceData.company.full_name || statics.empty }}
                      </div>
                    </div>
                    <div class="form__field form__field--margin">
                      <div class="modal__provision-label">{{ $t(`policyProvision.${"service"}`) }}:</div>
                      <div class="modal__provision-data">
                        {{
                          selectedPolicyService.service[`long_${language}`] ||
                          statics.empty
                        }}
                      </div>
                    </div>
                    <div class="form__field">
                      <div class="modal__provision-label">{{ $t(`policyProvision.${"policyNr"}`) }}:</div>
                      <div class="modal__provision-data">
                        {{ serviceData.policy_number || statics.empty }}
                      </div>
                    </div>
                    <div class="form__field form__field--margin">
                      <div class="modal__provision-label">{{ $t(`policyProvision.${"agent"}`) }}:</div>
                      <div class="modal__provision-data">
                        {{
                          `${serviceData.agent.first_name} ${serviceData.agent.last_name}`
                        }}
                      </div>
                    </div>
                    <div class="form__field">
                      <div class="modal__provision-label">{{ $t(`policyProvision.${"date"}`) }}:</div>
                      <div class="modal__provision-data">
                        {{ `${startDate} - ${endDate}` }}
                      </div>
                    </div>
                    <div class="form__field form__field--margin">
                      <div class="modal__provision-label">{{ $t(`policyProvision.${"supervisor"}`) }}:</div>
                      <div v-if="this.serviceData.agent.supervisors > 0">
                        <div
                            class="modal__provision-data"
                            v-for="(supervisor, index) in supervisors"
                            :key="supervisor"
                        >
                          <div
                              class="modal__provision-arrow"
                              v-if="index !== 0 && index !== supervisors.length"
                          ></div>
                          {{ supervisor }}
                        </div>
                      </div>
                    </div>
                    <div class="form__field ">
                      <div class="modal__provision-label">{{ $t(`policyProvision.${"salesCategory"}`) }}:</div>
                      <div class="modal__provision-data" v-if="serviceData.agent.sales_category">
                        {{ serviceData.agent.sales_category.name }}
                      </div>
                    </div>
                  </div>
                </div>

                 

                <div class="modal__provision-container modal__provision-statuses" v-if="selectedPolicyService.policy_service_payments.length > 0">

                  <div class="modal__provision-row" style="margin-bottom: 20px"  v-for="(servicePayment, index) in selectedPolicyService.policy_service_payments" :key="servicePayment.id" >
                    <div class="provision-wrapper">
                      <div class="form__field form__field--margin  form__field-vertical" style="margin-bottom: 0;">

                        <div class="modal__provision-label">{{ $t(`policyProvision.${"receivedOn"}`) }}:</div>
                        <span
                            v-if="serviceData.agent.sales_category"
                            class="editable"
                            @click="handleDateEdit(servicePayment, index)"

                        >
                      <!-- @click="servicePayment.paid == true ? null : (policyPaymentEditors[index].showTotalEditor = true)" -->
                        <PopupEditer
                            v-if="policyPaymentEditors.length >0 && policyPaymentEditors[index].showPendingDateSubmitter"

                            :isDate="true"
                            @togglePopup="(policyPaymentEditors[index].showPendingDateSubmitter) = $event"
                            :selected="servicePayment.pending_date"
                            @submit="openPendingDateSubmitter($event, servicePayment.id, index)"
                        />
                        {{ `${formatDate(servicePayment.pending_date)}` }}
                </span>

                      </div>

                      <div class="form__field form__field--margin  form__field-vertical" style="margin-bottom: 0;">

                        <div class="modal__provision-label ">{{ $t(`policyProvision.${"provision"}`) }}:</div>
                        <span
                            v-if="serviceData.agent.sales_category"
                            class="editable"

                            @click="handleEdit(servicePayment, index)"
                        >


                      <!-- @click="servicePayment.paid == true ? null : (policyPaymentEditors[index].showTotalEditor = true)" -->
                        <PopupEditer
                            :isPrice = true
                            v-if="policyPaymentEditors.length >0 && policyPaymentEditors[index].showTotalEditor"
                            @togglePopup="(policyPaymentEditors[index].showTotalEditor) = $event"
                            :selected="servicePayment.total"
                            @submit="openSubmitter($event, servicePayment.id)"
                        />
                        {{ servicePayment.total }} .- CHF
                      </span>
                        <span v-else>
                          {{ $t(`policyProvision.${"agentNoSaleCaregory"}`) }}
                        </span>
                      </div>

                    </div>
                    <div class="payment-status-wrapper">
                      <div
                          :class="
                          `payment-status
                          ${checkServicePaymentPaidStatus(servicePayment) ? (servicePayment.is_return ? 'payment-status-canceled' : 'payment-status-paid') : 'payment-status-pending'}`
                        "
                      >
                   
                        <div v-if="checkServicePaymentPaidStatus(servicePayment)">  
                          <div :class="`icon ${servicePayment.is_return ? 'icon__payment--canceled' : 'icon__payment--paid'}`"></div> 
                        </div>
                        <div v-else>
                          <div class="icon icon__payment--pending"></div> 
                        </div>

                        <div>
                          <p>
                            <span v-if="checkServicePaymentPaidStatus(servicePayment)">
                              {{ servicePayment.is_return  ? $t(`policyProvision.${"canceled"}`) : $t(`policyProvision.${"paid"}`) }}  
                            </span>
                            <span v-else>
                              {{ $t(`policyProvision.${"pending"}`) }}
                            </span>
                          </p>
 
                          <p class="created-at" >
                            {{ checkServicePaymentPaidStatus(servicePayment) ?  `${formatDate(servicePayment.paid_date)}` : '' }}
                          </p>
                        </div>
                        
                      </div>                  
                      <div
                          v-if="!showAddNewPolicyPayment && !servicePayment.paid"
                          class="payment-status payment-status-make-payment"
                          @click="confirmPayment = true; otherPaymentId = servicePayment.id"
                      >
                        {{ $t(`policyProvision.${"makePayment"}`) }}
                      </div> 
                      <div v-if="
                            showAddNewPolicyPayment &&
                            additionalPayments.length === 0 &&
                            selectedPolicyService.policy_service_payments.every(payment => payment.paid === 1) &&
                            index === 0
                          " class="payment-status payment-status-add">
                        <div 
                          
                          class="payment-status-add-img" 
                          @click="addPolicyServicePayment(selectedPolicyService.id); this.paymentIdToEdit = null ;this.paymentValueToEdit = null"
                        >
                          <img src="../assets/icons/default/add_icon.svg">
                        </div>
                        
                      </div>

                      <div class="payment-status-delete-img" @click="openDeletePayment(servicePayment.id)">

                          <img src="../assets/icons/default/delete_icon.svg"> 
                        </div>
                    </div>
                  </div>
                </div>

                <div v-if="additionalPayments.length > 0  || selectedPolicyService.policy_service_payments.length == 0" class="modal__provision-container">

                  <div class="modal__provision-row" v-for="additionalPayment in additionalPayments" :key="additionalPayment.id">

                  <div class="form__field form__field--margin">

                      <div class="modal__provision-label">{{ $t(`policyProvision.${"provision"}`) }}: </div>

                      <span
                          class="editable"
                          @click=" showTotalEditer2 = true"
                      >
                        <PopupEditer
                            :isPrice = true
                            :allowNegative = true
                            v-if="showTotalEditer2"
                            @togglePopup="showTotalEditer2 = $event"
                            :selected="additionalPayment.total"
                            @submit="openSubmitter2($event)"
                        />
                        {{ additionalPayment.total }} .- CHF
                      </span>
                    </div>
                    <div class="payment-status-wrapper">
                      <div :class="`payment-status payment-status-unpaid `">
                        <div :class="`icon icon__payment`"></div>
                        <p>{{ $t(`policyProvision.${"unpaid"}`) }}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>


              <div
                  v-if="isRequesting"
                  class="button modal__button modal__button--submit button--default button--loading"
              ></div>
              <button
                  v-if="showButton && !isRequesting"
                  @click="updateTotal"
                  class="button modal__button modal__button--submit button--default"
              >
                {{ $t(`buttons.${"saveBtn"}`) }}
              </button>
            </div>
          </transition>
        </div>
      </div>


      <div class="modal__submit" v-if="showSubmitter">
        <div class="modal__submit-panel">
          <div class="modal__submit-icon"></div>
          <div class="modal__submit-border"></div>
          <div class="modal__submit-container">
            <div class="modal__submit-text">
              {{ $t(`policyProvision.${"provisionChanges"}`) }}?
            </div>
            <div class="modal__submit-buttons">
              <button class="modal__submit-button" @click="applyTotal(paymentIdToEdit)">
                {{ $t(`buttons.${"yesBtn"}`) }}
              </button>
              <button class="modal__submit-button" @click="closeSubmitter()">
                {{ $t(`buttons.${"cancelBtn"}`) }}
              </button>
            </div>
          </div>
        </div>
      </div>

      <div class="modal__submit" v-if="confirmPayment">
        <div class="modal__submit-panel">
          <div class="modal__submit-icon"></div>
          <div class="modal__submit-border"></div>
          <div class="modal__submit-container">
            <div class="modal__submit-text">
              {{ $t(`policyProvision.${"confirmPayment"}`) }}?
            </div>
            <div class="modal__submit-buttons">
              <button class="modal__submit-button" @click="updateStatus(otherPaymentId)">
                {{ $t(`buttons.${"yesBtn"}`) }}
              </button>
              <button class="modal__submit-button" @click="confirmPayment = false">
                {{ $t(`buttons.${"cancelBtn"}`) }}
              </button>
            </div>
          </div>
        </div>
      </div>

      <div class="modal__submit" v-if="showDeleteProvisionModal">
        <div class="modal__submit-panel">
          <div class="modal__submit-icon"></div>
          <div class="modal__submit-border"></div>
          <div class="modal__submit-container">
            <div class="modal__submit-text">
              {{ $t(`policyProvision.${"deletePayment"}`) }}?
            </div>
            <div class="modal__submit-buttons">
              <button class="modal__submit-button" @click="deletePayment()">
                {{ $t(`buttons.${"yesBtn"}`) }}
              </button>
              <button class="modal__submit-button" @click="closeDeleteProvisionModal()">
                {{ $t(`buttons.${"cancelBtn"}`) }}
              </button>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import PopupEditer from "@/partials/PopupEditer";
import { parseCustomerName } from "@/helpers/entityParsers";
import { formatDisplayDate } from "@/helpers/dateFormatters";
//import router from "@/router";
import statics from "@/statics";
export default {
  name: "PolicyProvisionModal",

  components: {
    PopupEditer,
  },

  data() {
    return {
      showDeleteProvisionModal: false,
      paymentToDeleteId: null,

      statics,
      showButton: false,
      showTotalEditer: null,
      showTotalEditer2: null,
      hasServicePayment: false,
      paid: 0,
      preTotal: 0,
      total: 0,
      previousPrice: 0,
      showSubmitter: false,
      showPendingDateSubmitter: false,
      isRequesting: false,
      parseCustomerName,
      formatDisplayDate,
      showMakePaymentButton: false,

      confirmPayment: false,


      additionalPayments:[],
      showAddNewPolicyPayment: false,
      policyPaymentEditors: [],
      otherPaymentId: '',

      pendingDateToEditId:null,
      paymentIdToEdit:null,
      paymentValToEdit:null
    };
  },

  watch: {
    total(total) {
      if (total !== this.previousPrice) {
        this.showButton = true;
      } else {
        this.showButton = false;
      }
    },


    additionalPayments: {
      handler(newVal) {
        if (newVal.length > 0) {
          const additionalTotal = newVal[0].total;
          if (additionalTotal !== this.previousPrice) {
            this.showButton = true;
          } else {
            this.showButton = false;
          }
        }
      },
      deep: true,
    },
  },

  computed: {
    ...mapGetters(["selectedPolicyService", "customer", "language"]),

    startDate() {
      return this.selectedPolicyService.start_date
          ? formatDisplayDate(this.selectedPolicyService.start_date)
          : this.statics.empty;
    },

    endDate() {
      return this.selectedPolicyService.end_date
          ? formatDisplayDate(this.selectedPolicyService.end_date)
          : this.statics.empty;
    },

    serviceData() {
      if (this.customer.policies.length) {
        return this.customer.policies.find(
            (policy) => policy.id === this.selectedPolicyService.policy_id
        );
      }
      return null;
    },

    supervisors() {
      if (this.serviceData.agent.supervisors && this.serviceData.agent.supervisors.length) {
        return this.serviceData.agent.supervisors.map(
            (supervisor) =>
                `${supervisor.first_name ||
                this.statics.empty} ${supervisor.last_name || this.statics.empty}`
        );
      }
      return this.statics.empty;
    },
  },

  mounted() { 
    this.initializePolicyPayments();
  },

  destroyed() {
    this.$store.commit("setSelectedPolicyService", {});
  },

  methods: {
    openDeletePayment(id){ 
      this.showDeleteProvisionModal = true;
      this.paymentToDeleteId = id;
    },

    formatDate(date) {
      const d = new Date(date);
      const day = String(d.getDate()).padStart(2, '0');
      const month = String(d.getMonth() + 1).padStart(2, '0'); // Months are zero-based
      const year = d.getFullYear();
      return `${day}.${month}.${year}`;
    },

    async deletePayment() {
      try {
        const policyServiceId = this.paymentToDeleteId;
        const result = await this.$store.dispatch("deletePolicyServicePayment", policyServiceId);

        if (result.success) {
          await this.$store.dispatch("getGlobalCustomer", this.customer.id);
          this.$store.commit("setToast", {
            display: true,
            type: "success",
            message: "Payment deleted successfully.",
          });
          this.closeDeleteProvisionModal();
          this.closeModal();
        } 
        else { 
          this.$store.commit("setToast", {
            display: true,
            type: "error",
            message: result.message,
          });
        }
      } catch (err) { 
        this.$store.commit("setToast", {
          display: true,
          type: "error",
          message: err.message || "An unexpected error occurred",
        });
      } finally {
        this.closeDeleteProvisionModal();
      }
    },


    closeDeleteProvisionModal(){
      this.showDeleteProvisionModal = false; 
      this.paymentToDeleteId = null;
    },

    initializePolicyPayments() {
      if (this.selectedPolicyService.policy_service_payments.length > 0) {
        this.selectedPolicyService.policy_service_payments.forEach(payment => {
          this.policyPaymentEditors.push({paymentId: payment.id, showTotalEditor: false, showPendingDateEditor: false});
        });
        const allPaid = this.selectedPolicyService.policy_service_payments.every(payment => payment.paid == true);
        this.showAddNewPolicyPayment = allPaid;
      } else {
        this.addPolicyServicePayment(this.selectedPolicyService.id);
      }
    },
    updateShowAddNewPolicyPayment() {
      this.additionalPayments = [];
      this.showAddNewPolicyPayment = false;
      this.policyPaymentEditors = [];
      this.otherPaymentId = '';

      this.isRequesting = false;

      this.paymentIdToEdit = null;
      this.paymentValToEdit = null;

      this.initializePolicyPayments();
    },



    checkServicePaymentPaidStatus(servicePayment){
      if(servicePayment.paid){
        return true;
      }else{
        return false
      }

    },

    handleEdit(servicePayment, index) {
      if (servicePayment.paid == 1) {
        return;
      }
      this.closeAllEditors();
      this.$set(this.policyPaymentEditors[index], 'showTotalEditor', true);
    },
    handleDateEdit(servicePayment, index) {

      this.closeAllEditors();
      this.$set(this.policyPaymentEditors[index], 'showPendingDateSubmitter', true);
    },

    closeAllEditors() {
      this.policyPaymentEditors.forEach(editor => {
        editor.showTotalEditor = false;
        editor.showPendingDateEditor = false;
      });
    },

    addPolicyServicePayment(id){
      this.additionalPayments.push({policy_service_id: id, total: 0})
    },

    // handleEdit(servicePayment) {
    //   if (!this.paid) {
    //     this.showTotalEditer = servicePayment.id;
    //   }
    // },

    togglePopup(servicePayment) {
      this.showTotalEditer = this.showTotalEditer === servicePayment.id ? null : servicePayment.id;
    },


    closeModal() {
      this.$store.commit("setShowPolicyProvisionModal", false);
    },

    openSubmitter(total, id) {
      this.showSubmitter = true;
      this.preTotal = total;
      this.paymentIdToEdit = id;
      this.paymentValueToEdit = total;
    },
    async openPendingDateSubmitter(date, id, index) {
      this.showPendingDateSubmitter = true;

      const response = await this.$store.dispatch("changePolicyServicePendingDate", {
        policyServicePaymentId: id,
        pendingDate: date,
      });

      this.$set(this.selectedPolicyService.policy_service_payments, index, response.data.data);

      this.pendingDateToEditId = id;

    },

    openSubmitter2(total) {
      this.additionalPayments[0].total = total;
      this.paymentValueToEdit = total;
    },

    applyTotal(id) {

      if (this.additionalPayments.length > 0) {
        this.additionalPayments[0].total = this.preTotal;
      } else {
        if(this.selectedPolicyService.policy_service_payments.length > 0){
          const payment = this.selectedPolicyService.policy_service_payments.find(payment => payment.id == id);
          if (payment) {
            payment.total = this.preTotal;
            this.showButton = true;
          }
        }

      }
      this.showSubmitter = false;
    },

    closeSubmitter() {
      this.preTotal = 0;
      this.paymentIdToEdit = '';
      this.showSubmitter = false;
    },



    async updateTotal() {
      this.isRequesting = true;
      try {

        const policyServiceId = this.paymentIdToEdit ? this.paymentIdToEdit : this.selectedPolicyService.id;

        const total = Number(this.paymentValueToEdit) ;

        const state = this.paymentIdToEdit ? 'update' : 'create';

        let {
          data: { data },
        } = await this.$store.dispatch("changePolicyServicePaymentPrice", {
          policyServiceId,
          total,
          state,
        });

        this.paymentValueToEdit = data.total;
        this.previousPrice = this.paymentValueToEdit;

        if(state == "update"){
          this.$store.commit("setSelectedPolicyService", {
            ...this.selectedPolicyService,
            policy_service_payments: [...data],
          });
        }else {
          this.selectedPolicyService.policy_service_payments.push(data);
          this.$store.commit("setSelectedPolicyService", {
            ...this.selectedPolicyService,
            policy_service_payments: [...this.selectedPolicyService.policy_service_payments],
          });
        }


        await this.$store.dispatch("getGlobalCustomer", this.customer.id);
        this.showMakePaymentButton = true;
        this.updateShowAddNewPolicyPayment();


      } catch (err) {
        console.error(err);
        this.isRequesting = false;
      }
      this.isRequesting = false;
      this.showButton = false;
    },


    async updateStatus(id) {
      this.isRequesting = true;

      try {
        let data;

        const policyServiceId = id;
        ({
          data: { data },
        } = await this.$store.dispatch("changePolicyServicePaidState", {
          policyServiceId,
          paid: true,
        }));
        this.paid = data.paid;

        this.$store.commit("setSelectedPolicyService", {
          ...this.selectedPolicyService,
          policy_service_payments: this.selectedPolicyService.policy_service_payments.map(payment =>
              payment.id === data.id ? data : payment
          ),
        });


        await this.$store.dispatch("getGlobalCustomer", this.customer.id);
        this.updateShowAddNewPolicyPayment();
        this.showMakePaymentButton = false;
        this.confirmPayment = false;



      } catch (err) {
        console.error(err);
      } finally {
        this.isRequesting = false;
        this.updateShowAddNewPolicyPayment();
        this.closeModal();
      }
    },


  },
};
</script>

<style scoped lang="scss">
.pending__date__wrapper {
  margin-left: 10px;
  padding-left: 10px;
  border-left: #9b9b9b 1px solid;
}
 .payment-status-name{
 
  text-align: center;
 }
.provision-wrapper {
  width: 50%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: stretch;
  .form__field {
    display: flex;
  }
}
.created-at{
  color: #7F8FA4;
  font-size: 12px;
  margin-top: 5px;
}

.payment-status{
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;
  height: 48px;
  width: 43%;
  transition: 0.3s ease;
  &-wrapper{
    display: flex;
    gap: 2.3%;
    justify-content: space-between;
    width: calc(50% - 10px);
  }
  &-unpaid{
    background-color: #7f8fa433;
  }
  &-pending{
    background-color:  rgba(255, 159, 15, 0.2)
  }
  &-paid{
    background-color: rgba(30, 189, 123, 0.2);
  }
  &-canceled{
    background-color: #FFD1D1;
  }
  &-make-payment{
    background-color: var(--bg-2);
    color: var(--text-1);
    cursor: pointer;
  }
  &-make-payment:hover{
    background-color: var(--hover-1);
  }
  p{
    color: var(--text-9);
    font-family: var(--muli-text-semi-bold);
    text-align: center;
  }
  &-add{ 
    gap: 30px;
  }
  &-add-img{
    background-color: var(--bg-2);
    border-radius: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    cursor: pointer;
    transition: 0.3s ease;
  }
  &-add-img:hover{
    background-color: var(--hover-1);
  }
  &-add-img img{
    width: 13px;
    height: 13px;
  }
  &-delete-img{
    width: 7%;
    align-items: center;
    height: 48px;
    display: flex;
  }
  &-delete-img img{
    cursor: pointer;
    max-width: 24px;
  }
}


.created-at{
  color: #7F8FA4 !important;
  text-align: left !important;
}

[data-theme="dark"] .created-at{
  color: white !important;
}

[data-theme="dark"] .payment-status-canceled{
  background-color: #B45F5F;

}

.add {
  &__form {
    width: 100% !important;
    height: initial !important;
  }
}

.modal {
  &__panel{
    height: auto !important;
  }
  &__provision,  &{
    padding: 28px 0;

    &-title {
      font-size: var(--medium);
    }

    &-title,
    &-label {
      color: var(--text-2);
    }

    &-label {
      margin-right: 10px;

    }



    &-data {
      color: var(--text-9);
      font-family: var(--muli-text-semi-bold);
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      max-width: 335px;
      display: flex;
    }

    &-container {
      margin-top: 25px;
      padding-top: 15px;
      border-top: 1px solid var(--border-10);

    }

    &-statuses {
      max-height: 170px;
      overflow: visible;
      overflow-y: scroll;
    }

    &-row {
      display: flex;
      flex-wrap: wrap;
      padding: 0 50px;

      .form__field {
        width: calc(50% - 10px);
        background: var(--bg-23);
        padding: 15px;
        border-radius: var(--radius-1);
        display: flex;
        align-items: center;
        margin-bottom: 15px;
        height: 48px;
        &--margin {
          margin-right: 20px;
        }

        &--status {
          cursor: pointer;
        }

        &--paid {
          background: var(--bg-25);
          border: 1px solid var(--border-2);
          .modal__provision-data {
            color: var(--text-5);
          }
        }

        &--unpaid {
          background: var(--bg-24);
          .modal__provision-data {
            color: var(--text-12);
          }
        }

        &--supervisors {
          width: 100%;
        }

        &--empty {
          background: 0;
        }

        &-vertical{
          flex-direction: column;
          display: flex;
          align-items: center;
          flex-wrap: wrap;
          justify-content: space-between;
          align-content: center;
          padding: 5px;
          height: 48px;
          .modal__provision-label {
            margin-right: 0px;
          }
        }
      }

      .button {
        background-color: var(--bg-2);
        justify-content: center;
        font-family: var(--muli-text-regular);

        &:hover {
          background-color: var(--hover-1);
        }
      }
    }

    &-arrow {
      background: url("../assets/icons/default/next_arrow.svg");
      height: 16px;
      width: 16px;
      margin: 0 10px;
    }
  }

  &__submit {
    position: absolute;
    height: 100%;
    width: 100%;
    background: var(--modal-bg-2);
    backdrop-filter: blur(10px);
    top: 0;
    left: 0;
    border-radius: inherit;
    display: flex;
    align-items: center;
    justify-content: center;

    &-panel {
      width: 370px;
      padding: 9px 15px;
      background: var(--text-5);
      border-radius: var(--radius-1);
      display: flex;
      align-items: center;
    }

    &-icon {
      background: url("../assets/icons/default/question_icon.svg");
      min-width: 30px;
      height: 30px;
    }

    &-border {
      width: 1px;
      height: 70px;
      margin: 0 15px;
      background: var(--border-16);
    }

    &-container {
      flex-direction: column;
      align-items: flex-start;
    }

    &-buttons,
    &-container {
      display: flex;
      justify-content: center;
    }

    &-button {
      border-radius: var(--radius-1);
      box-shadow: 0px 3px 6px var(--shadow-3);
      color: var(--text-2);
      padding: 5px 0 5px 0;
      width: 100px;
      transition: all 0.3s ease;

      &:hover {
        color: var(--text-5);
        box-shadow: none;
      }

      &:first-child {
        margin-right: 50px;
      }
    }

    &-text {
      color: var(--text-1);
      margin-bottom: 15px;
    }
  }
}

[data-theme="dark"] .modal__submit-button{
  background-color: #313B47 !important;
}
</style>
